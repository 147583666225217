<template>
    <div>
        <div class="menu-group" v-for="m in menu" :key="m.menu_id">
            <div class="title-group">{{ m.title }}</div>
            <ul class="menu-ul">
                <li v-for="s in m.children" :key="s.menu_id">
                    <div @click="jumpToUrl(s)" :class="{active:active_id===s.menu_id.toString()}" class="title">
                        <span class="menu-icon" :class="['menu_'+s.icon]">
                        <svg class="icon" aria-hidden="true">
                            <use :href="'#icon-'+s.icon"></use>
                        </svg>
                        </span>
                        {{ s.title }}
                    </div>
                </li>
            </ul>
        </div>
    </div>
</template>
<script>
import storage from "@/utils/storage";

export default {
    computed: {
        active_id: function () {
            let path = this.$route.meta.auth||this.$route.path;
            // console.log(this.$route);
            for (let u of this.$store.state.permission) {
                if (u.url === path && u.type === 'menu') {
                    document.title = u.title;
                    return u.menu_id.toString();
                }
            }
            return '';
        },
        menu: function () {
            let menu = this.$store.state.permission.filter((v) => v.type === 'menu');
            return this.tools.loop(menu, 'menu_id');
        },
    },
    methods: {
        jumpToUrl(item) {
            if (item.url === '/user/setting/out') {
                this.$confirm('你真的要退出登录吗?', '退出确认').then(() => {
                    storage.clear();
                    this.$router.push({
                        path: '/index'
                    });
                });
            } else {
                this.$router.push({
                    path: item.url
                });
            }
        }
    }
}
</script>
<style scoped lang="less">
.menu-group {
    //padding: 10px;
    margin-top: 10px;
    background-color: #fff;
    color: #333;
}

.title-group {
    font-weight: 500;
    padding: 10px 20px;
}

.title {
    padding: 12px 20px;
    font-size: 14px;
    display: flex;
    align-items: center;
    cursor: pointer;

    .menu-icon {
        //color: #231F20;
        display: inline-block;
        padding-right: 10px;
        font-size: 16px;
        color: #25BFBF;
        //color: yellow;

    }
    .menu_wenda{
        color: #0266FF;
    }
    .menu_tuichudenglu{
        color: #F4C808;
    }

    &.active {
        background: #307DDB;
        color: #fff;

        .menu-icon {
            color: #fff;
        }
    }
}
</style>