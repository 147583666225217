<template>
    <el-row>
        <el-col>
            <div class="footer bbs-third-color">
                <div>
                    <a href="http://www.bigemap.com/aboutus/clause/" class="info-link" target="_blank">隐私政策</a>
                    <a href="http://www.bigemap.com/app/pay-a.html" class="info-link" target="_blank">Bigemap用户服务协议</a>
                </div>
                <div>
                    <a class="info-link" href="javascript:void(0);" target="_blank">
                        Copyright© 成都比格图数据处理有限公司 版权所有
                    </a>
                    <a class="info-link" href="https://beian.miit.gov.cn/" target="_blank">
                        蜀ICP备14013992-2号
                    </a>
                </div>
            </div>
        </el-col>
    </el-row>
</template>

<script>
export default {
    name: "system-footer",
    data() {
        return {
            baseUrl: '/bbs/system/footer',
        }
    },
    created() {

    },
    methods: {}
}
</script>

<style lang="less" scoped>
.footer {
    border-radius: 5px;
    margin: 20px 10px;
    background-color: #fff;
    padding: 30px 15px;
    >div:first-child{
        margin-bottom: 15px;
    }
    a{
        text-decoration: none;
        color: #B3C1D3;
        display: inline-block;

        padding-right: 30px;
    }
}
</style>
