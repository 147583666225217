<template>
    <div>
        <div class="search">
            <index-search></index-search>
        </div>
        <rank></rank>
        <div class="ad">
            <div class="title">BIGEMAP”一心三端”助力全行业打造数字孪生世界</div>
            <div>
                <button class="btn">立即体验</button>
            </div>
        </div>
        <div class="index-answer-list base-width">
            <el-row :gutter="15">
                <el-col :span="17" :offset="1">
                    <question-list mode="index"></question-list>
                </el-col>
                <el-col :span="5">
                    <today-hot></today-hot>
                </el-col>
            </el-row>
        </div>
    </div>
</template>
<script>
import IndexSearch from "@/components/index/top/index-search.vue";
import Rank from '@/components/index/rank/index.vue';
import QuestionList from "@/components/wenda/left/question-list.vue";
import TodayHot from "@/components/wenda/right/today-hot.vue";
import {JUMP_LOGIN_OK, JUMP_URL} from "@/utils/eventList";
import storage from "@/utils/storage";


export default {
    name: "index",
    components: {
        IndexSearch, Rank, QuestionList, TodayHot
    },
    mounted() {
        // var b=decode('cgP9i+pUT0QfDMdEhf6P/pm4Gekbbl4h5lFFpTahoNFK3fCjIWgLkUICz+WRlF+72KfPxmHyrhsKFlD1/eKCphfcCnOnhbjFZcYiao52H4XUEwG0OA1HrRHjOCiL3m/x','0000000000000000');
        // console.log(b);
        //如果是别处跳转过来的，传递了 token和jump url,那么要进行登录操作
        setTimeout(()=>{
            let query = this.$route.query;
            if (query.jump_url) {
                storage.storage(JUMP_URL, {url:decodeURIComponent(query.jump_url)});
            }
            console.log('init query',query);
            if (query.token) {
                this.$bbs.post(this.baseUrl, {
                    token: query.token
                }).then(data => {
                    //如果提交成功了，那么会提交到登录的信息中
                    console.log('ok',data);
                    this.$event.$emit(JUMP_LOGIN_OK, data);
                });
            }
        },50);
    },
    created() {

    },
    data: function () {
        return {
            baseUrl: '/bbs/api/tlo',
        }
    },
    methods: {}
}

</script>
<style scoped lang="less">
.search {
    height: 340px;
    align-items: center;
    justify-content: center;
    display: flex;
    background: url("/static/bbs/images/index/search_bg.png") top center no-repeat;
    background-size: cover;
}

.ad {
    margin-top: 20px;
    background: url("/static/bbs/images/index/info.png") top center no-repeat;
    background-size: cover;
    //height: 90px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    color: #fff;
    padding: 30px 0;

    .title {
        font-family: YouSheBiaoTiHei;
        font-size: 26px;
        padding-bottom: 10px;
    }

    .btn {
        font-family: YouSheBiaoTiHei;
        color: #fff;
        border: none;
        font-size: 24px;
        border-radius: 50px;
        background-color: rgba(48, 125, 219, 1);;
        padding: 4px 40px;
    }
}

.index-answer-list {
    padding: 0 8px;
    margin-top: 20px;
}
</style>
