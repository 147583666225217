<template>
    <div style="background-color: #fff">
        <div class="pt-3">
            <search @condition="condition" :fields="searchFields">
                <!--                <el-button @click="addUser" v-access="{url:baseUrl,method:'POST'}" size="medium" slot="add"-->
                <!--                           icon="el-icon-plus" type="primary">添加-->
                <!--                </el-button>-->
            </search>
        </div>
        <div class="mt-3">
            <my-table :data="tableData" :fields="showFields">
                <el-table-column slot="operation" fixed="right" width="150" label="操作">
                    <template slot-scope="scope">
                        <div class="operation-list">
                            <el-link type="primary" @click="seeDetail(scope)">查看</el-link>
                            <el-link type="primary" v-access="{url:baseUrl,method:'PUT'}"  @click="editAnswer(scope)">编辑</el-link>
                            <el-link type="primary"  v-access="{url:baseUrl,method:'PUT'}"  v-if="[1,0].indexOf(scope.row.review_status)>-1"  @click="verifyAnswer(scope.$index,scope.row)">审核</el-link>
                            <el-link type="danger" v-access="{url:baseUrl,method:'DELETE'}"  @click="delAnswer(scope)">删除</el-link>
                        </div>
                    </template>
                </el-table-column>
            </my-table>
        </div>
        <div class="pb-3 mt-3">
            <el-pagination
                @size-change="sizeChange"
                @current-change="pageChange"
                :current-page="page.pageNumber"
                :page-sizes="[10, 15, 30, 50]"
                :page-size="page.pageSize"
                :background="true"
                layout="sizes,prev, pager,next,total"
                :total="page.total">
            </el-pagination>
        </div>
        <form-dialog @closeFormDialog="submit" :title="form.title" :show="form.show" :fields="form.fields"
                     :rules="form.rules" :data="form.data"></form-dialog>
    </div>
</template>
<script>
import F from '@/fields/fields';
import search from "@/components/common/search";
import MyTable from "@/components/common/my-table";

export default {
    name: "appSms",
    components: {
        search, MyTable
    },
    created() {
        this.$store.dispatch('getDict', ['pro_product']);
    },
    mounted() {

    },
    data: function () {
        return {
            baseUrl: '/bbs/answer/manage',
            page: {
                search: {},
                sort: {},
                total: 1,
                pageSize: 10,
                pageNumber: 1,
            },
            tableData: [],
            form: {
                action_name: '',
                show: false,
                title: '',
                data: {},
                fields: [],
                rules: {},
            }
        }
    },
    computed: {
        searchFields: function () {
            return F.search('answer', 'manage_search');
        },
        showFields: function () {
            return F.show('answer', 'manage_show');
        },
    },
    methods: {
        condition: function (search) {
            this.page.search = search;
            this.page.pageNumber = 1;
            this.search();
        },

        search: function () {
            this.$bbs.get(this.baseUrl, {
                params: this.page,
            }).then(data => {
                this.page.total = data.data.total;
                this.page.pageNumber = data.data.pageNumber;
                this.page.pageSize = data.data.pageSize;
                this.tableData = data.data.data;
            });
        },
        sortChange: function (column) {
            if (!column.order) return;
            this.page.sort = {prop: column.prop, order: column.order};
            this.search();
        },
        sizeChange: function (size) {
            this.page.pageSize = size;
            this.search();
        },
        pageChange: function (page) {
            this.page.pageNumber = page;
            this.search();
        },
        verifyAnswer(index, data){
            let a = document.createElement('a');
            a.target = '_blank';
            a.href = '/wenda/detail/' + data.answer_id + '.html?verify=1&type=answer&reply_id=' + (data.reply_id || '') + '&comment_id=' + (data.comment_id || '');
            a.click();
        },
        submit: function (data) {
            if (data.type === 'cancel') {
                this.form.show = false;
                return;
            }
            switch (this.actionName) {
                case 'addInvoice':
                    this.$bbs.post(this.addInvoiceUrl, data.data).then(adData => {
                        this.$message.success('申请成功!');
                        this.tableData.splice(this.index, 1, adData.data);
                        this.form.show = false;
                    });
                    break;
                case 'editFree':
                    this.$bbs.put(this.baseUrl, data.data).then(adData => {
                        this.$message.success('保存成功!');
                        this.tableData.splice(this.index, 1, adData.data);
                        this.form.show = false;
                    });
                    break;
                case 'addFree':
                    this.$bbs.post(this.baseUrl, data.data).then(userData => {
                        this.page.total++;
                        this.form.show = false;
                        this.$message.success('添加成功!');
                        this.tableData.unshift(userData.data);
                    });
                    break;
            }
        },
        editAnswer: function (row) {
            let a=document.createElement('a');
            a.target='_blank';
            a.href='/add?answer_id='+row.row.answer_id;
            a.click();
        },
        seeDetail: function (row) {
            let a=document.createElement('a');
            a.target='_blank';
            a.href='/wenda/detail/'+row.row.answer_id+'.html';
            a.click();
        },
        delAnswer: function (data) {
            this.$confirm('你真的要删除这条记录吗?', '操作确认').then(() => {
                this.$bbs.delete(this.baseUrl, {data: {id: data.row.answer_id}}).then(() => {
                    this.tableData.splice(data.$index, 1);
                    this.page.total--;
                    this.$message.success('删除成功!');
                });
            });
        }
    }
}
</script>

<style lang="less" scoped>
.operation-list{
    display: flex;
    justify-content: space-around;
}
</style>
