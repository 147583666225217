<template>
    <div>
        <my-table row-key="product_id" :data="userList" :fields="showFields">
            <template v-slot:bindstatus="status">
                <div class="text-center">
                <span class="enable-status" :class="{enable:status.data===1}">
                    {{status.data===1?'启用':'禁用'}}
                </span>
                </div>
            </template>
            <el-table-column slot="operation" width="240" label="操作">
                <template slot-scope="scope">
                    <div class="operation-a">
                        <a @click="editUser(scope.row.true_index)" v-access="{url:authUrl.baseUrl,method:'PUT'}" class="edit" href="javascript:void(0);">修改信息</a>
                        <a v-if="net_type==='buy'" @click="changeManage(scope.row.true_index)" v-access="{url:authUrl.baseManageUrl,method:'PUT'}" class="transfer" href="javascript:void(0);">转让管理员</a>
                        <a v-if="net_type==='buy'"  @click="removeUser(scope.row.true_index)" v-access="{url:authUrl.baseUrl,method:'DELETE'}" class="remove" href="javascript:void(0);">删除</a>
                    </div>
<!--                    <el-button-->
<!--                        size="mini"-->
<!--                        @click="editUser(scope.$index)" v-access="{url:authUrl.baseUrl,method:'PUT'}"-->
<!--                        icon="el-icon-edit"></el-button>-->
<!--                    <el-button-->
<!--                        v-if="net_type==='buy'"-->
<!--                        size="mini"-->
<!--                        type="warning"-->
<!--                        @click="changeManage(scope.$index)" v-access="{url:authUrl.baseManageUrl,method:'PUT'}"-->
<!--                        icon="el-icon-user"></el-button>-->
<!--                    <el-button-->
<!--                        v-if="net_type==='buy'"-->
<!--                        size="mini"-->
<!--                        type="danger"-->
<!--                        @click="removeUser(scope.$index)" v-access="{url:authUrl.baseUrl,method:'DELETE'}"-->
<!--                        icon="el-icon-remove"></el-button>-->
                </template>
            </el-table-column>
        </my-table>
    </div>
</template>
<script>
import F from "@/fields/fields";

export default {
    props: {
        authUrl:{

        },
        net_type: {
            type: String,
            default: '',
        },
        users: {
            type: Array,
            default: function () {
                return [];
            }
        }
    },
    data() {
        return {
            // baseUrl: '/bbs/net/list',
            // baseAddUrl: '/bbs/net/jo',
            // baseManageUrl: '/bbs/net/rma',
            userList: [],
        }
    },
    watch: {
        users: function () {
            this.updateUser();
        }
    },
    computed: {
        showFields: function () {
            if (this.net_type==='buy'){
                return F.show('proNetMapping', 'show_buy');
            }else{
                return F.show('proNetMapping', 'show_join');
            }
        },
    },
    methods: {
        updateUser: function () {
            this.userList.splice(0, 10000);
            this.users.map((v, index) => {
                // this.$set(v,'company_name_alias',this.company_name);
                this.userList.push(v);
            });
        },
        editUser: function (index) {
            this.$emit('handle', {type: 'edit', index});
        },
        changeManage: function (index) {
            this.$emit('handle', {type: 'manage', index});
        },
        removeUser: function (index) {
            this.$emit('handle', {type: 'remove', index});
        }
    }
}
</script>
<style scoped lang="less">
    .enable-status{
        border: 1px solid #FF354D;
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        font-size: 14px;
        color: #FF354D;
        display: inline-block;
        padding: 2px 5px;
        line-height: 20px;
        text-align: right;
        font-style: normal;
        background: rgba(255,53,77,0.38);
        &.enable{
            color: #03E270;
            background: rgba(114,228,148,0.38);
            border: 1px solid #00E760;
        }
    }
    .operation-a{
        display: flex;
        justify-content: space-around;
        align-items: center;
        a{
            text-align: center;
            display: block;
            width: 33%;
            text-decoration: underline;
        }
        .edit{
            color: #333;
        }
        .remove{
            color: #FF4D55;
        }
    }

</style>