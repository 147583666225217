<template>
    <div class="type">
        <div class="item" @click="change('buy')" :class="{active:value==='buy'}">
            <span>我购买的</span>
        </div>
        <div class="item"  @click="change('join')" :class="{active:value==='join'}">
            我加入的
        </div>
    </div>
</template>
<script>
    export default {
        props:['value'],
        data(){
            return {

            }
        },
        methods:{
            change(v){
                this.$emit('input',v);
            }
        }
    }
</script>
<style scoped lang="less">
    .type{
        display: flex;
        user-select: none;
        .item{
            width: 110px;
            text-align: center;
            padding: 8px 15px;
            margin-right: 30px;
            border: 1px solid transparent;
            color: #666;
            cursor: pointer;
            &.active{
                font-weight: 500;
                color: #007EE2;
                border: 1px solid #007EE2;
            }
        }
    }
</style>