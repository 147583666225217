<template>
    <div style="background-color: #fff">
        <div style="padding-top: 24px">
            <buy-join v-model="my_net_type"></buy-join>
        </div>
        <div class="company-container">
            <div @click="clickCompany(com)" class="company" v-for="com in company.list" :current="company.current"
                 :key="com.net_id">
                <company-item  :current="company.current" :company="com">
                    <template v-slot:edit="edit">
                        <span @click.stop="editCompany" v-access="{url:baseUrl,method:'PUT'}" v-if="my_net_type==='buy'&&edit&&edit.edit.net_id===company.current.net_id" class="edit-link"><i class="el-icon-edit"></i> 编辑</span>
                    </template>
                </company-item>
            </div>
        </div>
        <div v-if="my_net_type==='buy'">
            <div class="text-right">
                <el-button @click="addUser" style="border-radius: 0" :disabled="add_disabled" v-access="{url:baseAddUrl,method:'POST'}"
                           size="medium"
                           icon="el-icon-plus" type="primary">
                    {{ add_text }}
                </el-button>
            </div>
            <div class="search-net-user">
                <el-input
                    size="mini"
                    prefix-icon="el-icon-search"
                    placeholder="搜索用户，支持备注、电话"
                    v-model="company.search"
                    clearable>
                </el-input>
            </div>
            <!--            <el-divider content-position="left">购买基本信息</el-divider>-->
            <!--            后来这里不要显示列表了-->
            <div class="mt-1" style="display: none">
                <my-table row-key="product_id" :data="companyData" :fields="showFields">
                    <el-table-column slot="operation" width="150" label="操作">
                        <template slot-scope="scope">
                            <!--                        <span class="my-sort-dom" :data-id="scope.row.product_id">-->
                            <!--                            <i class="el-icon-rank"></i>-->

                            <!--                        </span>-->
                            <!--                        <el-button-->
                            <!--                            size="mini"-->
                            <!--                            :data-id="scope.row.product_id"-->
                            <!--                            class="my-sort-dom"-->
                            <!--                            v-access="{url:baseUrl,method:'PUT'}"-->
                            <!--                            icon="el-icon-rank">-->
                            <!--                        </el-button>-->
                            <el-button
                                size="mini"
                                @click="editCompany(scope.$index)" v-access="{url:baseUrl,method:'PUT'}"
                                icon="el-icon-edit"></el-button>
                        </template>
                    </el-table-column>
                </my-table>
            </div>
        </div>
        <div class="pt-3 pt-3">
            <el-divider content-position="left">{{ my_net_type === 'buy' ? '用户列表信息' : '我加入的网络版列表' }}
            </el-divider>
        </div>
        <div class="mt-3">
            <join-list :auth-url="{baseUrl,baseAddUrl,baseManageUrl}" @handle="handleUserClick" :net_type="my_net_type"
                       :users="users"></join-list>
        </div>
        <form-dialog @closeFormDialog="submit" :title="form.title" :show="form.show" :fields="form.fields"
                     :rules="form.rules" :data="form.data"></form-dialog>
    </div>
</template>
<script>
import F from '@/fields/fields';
import search from "@/components/common/search";
import MyTable from "@/components/common/my-table";
import JoinList from "@/components/user/buy/join-list.vue";
import BuyJoin from "@/components/user/buy/buy-join.vue";
import CompanyItem from "@/components/user/buy/company-item.vue";
// import Sortable from 'sortablejs';

export default {
    components: {
        search, MyTable, JoinList, BuyJoin, CompanyItem
    },
    created() {

    },
    mounted() {
        if (this.$route.query&&this.$route.query.buy_type==='join'){
            //在订单的页面，如果一个用户是企业版，在购买订阅版的时候，会有提示，
            //并且这个提示跳转的地址中会带这个参数,这样方便用户直接就看到自己加入的企业
            this.my_net_type='join';
        }
        this.search();
    },
    data: function () {
        return {
            baseUrl: '/bbs/net/list',
            baseAddUrl: '/bbs/net/jo',
            baseManageUrl: '/bbs/net/rma',
            my_net_type: 'buy',
            page: {
                search: {},
                sort: {},
                total: 1,
                pageSize: 15,
                pageNumber: 1,
            },
            // users: [],
            company: {
                list: [],
                search:'',
                current: {},
                index: -1,
            },
            companyDetail: {
                net_sum: 0,
                company_name_alias: '',
                net_id: '',
            },
            form: {
                action_name: '',
                show: false,
                title: '',
                data: {},
                fields: [],
                rules: {},
            }
        }
    },
    watch: {
        my_net_type: function () {
            this.company.search='';
            this.search();
        },
    },
    computed: {
        searchFields: function () {
            return F.search('proNetList', 'search');
        },
        showFields: function () {
            return F.show('proNetList', 'show');
        },
        companyData: function () {
            if (!this.companyDetail.net_id) return [];
            return [this.companyDetail];
        },
        add_disabled: function () {
            let current=this.company.current;
            let can = current&&current.net_id && current.net_sum && current.users&&current.users.length < parseInt(current.net_sum);
            return !can;
        },
        add_text: function () {
            if (!this.company.current.net_sum) return '添加人员';
            return '添加人员' + [this.company.current.users.length+'/', this.company.current.net_sum].join('');
        },
        users: function () {
            let index = this.company.list.findIndex(v => v.net_id === this.company.current.net_id);
            //这里保存这个index是方便后面修改的时候，使用
            this.company.index = index;
            if (index === -1) return [];
            let search=this.company.search.trim();
            let users=[];
            this.company.list[index].users.map((v,index)=>{
                //这里记录这个真实的index
                v.true_index=index;
                if (search!==''&&v.nickname.indexOf(search)===-1&&v.phone.indexOf(search)===-1){
                    return;
                }
                users.push(v);
            });
            return  users;
        }
    },
    methods: {
        // condition: function (search) {
        //     this.page.search = search;
        //     this.page.pageNumber = 1;
        //     this.search();
        // },
        search: function () {
            // this.users.splice(0, 100000);
            let current_type = this.my_net_type;
            let url = current_type === 'buy' ? this.baseUrl : this.baseAddUrl;
            this.$bbs.get(url, {
                params: this.page,
                need:{
                    error:true,
                }
            }).then(data => {
                this.company.list = data.data.detail;
                if (this.company.list.length) {
                    this.company.current = this.company.list[0];
                }
            });
        },
        sortChange: function (column) {
            if (!column.order) return;
            this.page.sort = {prop: column.prop, order: column.order};
            this.search();
        },
        sizeChange: function (size) {
            this.page.pageSize = size;
            this.search();
        },
        pageChange: function (page) {
            this.page.pageNumber = page;
            this.search();
        },
        filterPlatform(value, row) {
            return row.tag === value;
        },
        submit: function (data) {
            // this.con(data);
            if (data.type === 'cancel') {
                this.form.show = false;
                return;
            }
            switch (this.actionName) {
                case 'editCompany':
                    this.$bbs.put(this.baseUrl, data.data, {
                        need: {
                            error: true,
                        }
                    }).then(adData => {
                        this.$message.success('保存成功!');
                        this.company.current.company_name_alias=adData.data.company_name_alias;
                        // this.companyDetail = adData.data;
                        this.form.show = false;
                    });
                    break;
                case 'addUser':
                    this.$bbs.post(this.baseAddUrl, data.data, {
                        need: {
                            error: true,
                        }
                    }).then(userData => {
                        this.page.total++;
                        this.form.show = false;
                        let index=this.company.list[this.company.index].users.findIndex(v=>v.username===userData.data.username);
                        if (index===-1){
                            this.$message.success('添加成功!');
                            this.company.list[this.company.index].users.unshift(userData.data);
                        }else{
                            this.$message.warning('存在此用户!');
                        }
                    });
                    break;
                case 'editUser':
                    //这里添加这个变量，方便后台判断是哪里来的修改操作，方便判断
                    data.data.from_type = this.my_net_type;
                    data.data.net_id = this.company.current.net_id;
                    this.$bbs.put(this.baseAddUrl, data.data, {
                        need: {
                            error: true,
                        }
                    }).then(userData => {
                        this.form.show = false;
                        this.$message.success('修改成功!');
                        //先获取原来的旧数据
                        let old_data = this.company.list[this.company.index].users[this.index];
                        //还原对应的手机号和公司的名称
                        userData.data.phone = old_data.phone;
                        userData.data.company_name_alias = old_data.company_name_alias;
                        this.company.list[this.company.index].users.splice(this.index,1,userData.data);
                    });
                    break;
            }
        },
        editCompany: function () {
            //因为我设置了只有选中的时候，才可以编辑，所以这里直接使用 this.company.current,this.company.index
            let form = F.get('proNetList', 'edit_company', this.company.current);
            this.actionName = 'editCompany';
            this.form = form;
            this.index = this.company.index;
        },
        addUser: function () {
            let form = F.get('proNetMapping', 'add_user', {net_id: this.company.current.net_id});
            this.actionName = 'addUser';
            this.form = form;
        },
        clickCompany(current) {
            this.company.search='';
            this.company.current = current;
        },
        handleUserClick(info) {
            let currentData=this.company.list[this.company.index].users[info.index];
            if (info.type === 'edit') {
                let form;
                if (this.my_net_type === 'buy') {
                    form = F.get('proNetMapping', 'edit_user_buy', currentData);
                } else {
                    form = F.get('proNetMapping', 'edit_user_join', currentData);
                }
                this.actionName = 'editUser';
                this.index = info.index;
                this.form = form;
            } else if (info.type === 'remove') {
                this.$confirm('你真的要删除这个用户吗?', '操作确认').then(() => {
                    this.$bbs.delete(this.baseUrl, {data: {id: currentData.mapping_id}}).then(() => {
                        this.company.list[this.company.index].users.splice(info.index,1);
                        this.$message.success('删除成功!');
                    });
                });
            } else if (info.type === 'manage') {
                this.$confirm('你确认要设置此人为管理员吗？确认后您将不能管理此网络版的用户', '设置管理员').then(() => {
                    this.$confirm('确认后您将不能管理此网络版的用户', '操作确认').then(() => {
                        this.$bbs.put(this.baseManageUrl, {
                            net_id: this.company.current.net_id,
                            username: currentData.username
                        },{
                            need: {
                                error: true,
                            }
                        }).then(() => {
                            this.$message.success('操作成功!');
                            setTimeout(() => {
                                location.reload();
                            }, 1000);
                        });
                    });
                });
            }
        },
        deleteServer: function (index, id) {
            this.$confirm('你真的要删除这个订阅吗?', '操作确认').then(() => {
                this.$axios.delete(this.baseUrl, {data: {id}}).then(() => {
                    this.tableData.splice(index, 1);
                    this.page.total--;
                    this.$message.success('删除成功!');
                });
            });
        }
    }
}
</script>

<style lang="less" scoped>
.search-net-user{
    width: 360px;
}
.company-container {
    display: flex;
    padding: 15px 0;

    .company {
        margin-right: 40px;
    }
}

.rank {
    display: inline-block;
    margin-right: 10px;
}
</style>
