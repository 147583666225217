<template>
    <div class="item" :class="{active:current&&current.net_id===detail.net_id}">
        <slot name="edit" :edit="detail">

        </slot>
        <div class="c-title">
            {{ detail.company_name_alias }}
        </div>
        <div class="t-v" v-for="item  in fields" :key="item.value">
            <div class="title">{{ item.title }}</div>
            <div class="value">{{ detail[item.value] }}</div>
        </div>
    </div>
</template>
<script>
export default {
    props: ['company','current'],
    data() {
        return {
            fields: [
                {title: '节点', value: 'net_sum'},
                {title: '管理员', value: 'manager_name'},
                {title: '到期时间', value: 'end_time_str'},
            ],
        }
    },
    computed: {
        detail: function () {
            return this.company;
        }
    },
    methods:{

    }
}
</script>
<style scoped lang="less">
.item{
    color: #333333;
    width: 240px;
    padding: 5px;
    border: 1px solid #EDEDED;
    cursor: pointer;
    position: relative;
    .edit-link{
        position: absolute;
        right: 2px;
        top: 2px;
        font-size: 12px;
    }
    .c-title{
        height: 34px;
        padding: 10px 0;
        font-weight: bold;
        overflow:hidden; //超出的文本隐藏
        text-overflow:ellipsis; //溢出用省略号显示
        white-space:nowrap; //溢出不换行
    }

    &.active{
        background: #007FEA;
        border-color: #007FEA;
        color: #fff;
    }
}
.t-v {
    display: flex;
    padding: 4px 0;
    .title {
        width: 80px;
        flex-shrink: 0;
    }

    .value {
        flex: 1
    }
}
</style>