<template>
    <div style="background-color: #fff;margin-top: 20px">
        <div class="pt-3">
            <search @condition="condition" :fields="searchFields">

            </search>
        </div>
        <div class="mt-3">
            <my-table :data="tableData" :fields="showFields">
                <el-table-column slot="operation" fixed="right" width="80" label="操作">
                    <template slot-scope="scope">
                        <el-button
                            size="mini"
                            type="primary"
                            @click="editUser(scope.$index)" v-access="{url:baseUrl,method:'POST'}"
                            icon="el-icon-edit"></el-button>
                    </template>
                </el-table-column>
            </my-table>
        </div>
        <div class="pb-3 mt-3">
            <el-pagination
                @size-change="sizeChange"
                @current-change="pageChange"
                :current-page="page.pageNumber"
                :page-sizes="[10, 15, 30, 50]"
                :page-size="page.pageSize"
                :background="true"
                layout="sizes,prev, pager,next,total"
                :total="page.total">
            </el-pagination>
        </div>
        <form-dialog @closeFormDialog="submit" :title="form.title" :show="form.show" :fields="form.fields"
                     :rules="form.rules" :data="form.data"></form-dialog>
    </div>
</template>
<script>
import F from '@/fields/fields';
import search from "@/components/common/search";
import MyTable from "@/components/common/my-table";
export default {
    name: "appSms",
    components: {
        search, MyTable
    },
    created() {

    },
    mounted() {
        //先请求
        this.$bbs.get(this.baseGroupUrl).then(data=>{
            let dict={bbs_user_group:[]};
            data.data.data.map(v=>{
                dict.bbs_user_group.push({label:v.group_title,value:v.group_id});
            });
            this.$store.commit('setDict',dict);
        });
    },
    data: function () {
        return {
            baseUrl: '/bbs/user/list',
            baseGroupUrl:'/bbs/userG/index',
            page: {
                search: {},
                sort: {},
                total: 1,
                pageSize: 10,
                pageNumber: 1,
            },
            sum: {},
            subscribe: [],
            userGroup: [],
            tableData: [],
            form: {
                action_name: '',
                show: false,
                title: '',
                data: {},
                fields: [],
                rules: {},
            }
        }
    },
    computed: {
        searchFields: function () {
            return F.search('bbsUser', 'search');
        },
        showFields: function () {
            return F.show('bbsUser', 'show');
        },
    },
    methods: {
        condition: function (search) {
            this.page.search = search;
            this.page.pageNumber = 1;
            this.search();
        },
        search: function () {
            this.$bbs.get(this.baseUrl, {
                params: this.page,
            }).then(data => {
                this.page.total = data.data.total;
                this.page.pageNumber = data.data.pageNumber;
                this.page.pageSize = data.data.pageSize;
                this.tableData = data.data.data;
            });
        },
        sortChange: function (column) {
            if (!column.order) return;
            this.page.sort = {prop: column.prop, order: column.order};
            this.search();
        },
        sizeChange: function (size) {
            this.page.pageSize = size;
            this.search();
        },
        pageChange: function (page) {
            this.page.pageNumber = page;
            this.search();
        },
        filterPlatform(value, row) {
            return row.tag === value;
        },
        submit: function (data) {
            // this.con(data);
            if (data.type === 'cancel') {
                this.form.show = false;
                return;
            }
            switch (this.actionName) {
                case 'editUser':
                    this.$bbs.put(this.baseUrl, data.data).then(adData => {
                        this.$message.success('保存成功!');
                        this.form.show = false;
                        //因为更新的数据没有这个内容，所以这里要还原数据
                        let old_data=this.tableData[this.index];
                        adData.data.phone=old_data.phone;
                        adData.data.nickname=old_data.nickname;
                        this.tableData.splice(this.index, 1, adData.data);
                    });
                    break;
            }
        },
        editUser: function (index) {
            let form = F.get('bbsUser', 'edit_user', this.tableData[index]);
            this.actionName = 'editUser';
            this.form = form;
            this.index = index;
        },
        deleteServer: function (index, id) {
            this.$confirm('你真的要删除这个订阅吗?', '操作确认').then(() => {
                this.$axios.delete(this.baseUrl, {data: {id}}).then(() => {
                    this.tableData.splice(index, 1);
                    this.page.total--;
                    this.$message.success('删除成功!');
                });
            });
        }
    }
}
</script>

<style lang="less" scoped>
.pay-sum {
    display: flex;

    .sum-item {
        flex: 1;
        margin: 10px;
        display: flex;
        height: 50px;
        border-radius: 5px;
        color: #fff;
        background-color: #45bbe0;

        &.danger {
            background-color: rgba(122, 54, 60, 0.85);
        }

        .title {
            flex-shrink: 0;
            width: 80px;
            display: flex;
            justify-content: center;
            align-items: center;
            height: 100%;
        }

        .sum {
            display: flex;
            align-items: center;
            height: 100%;
            font-weight: bold;
            justify-content: center;
            font-size: 18px;
        }
    }
}
</style>
