<template>
    <div class="c-radio">
        <div v-for="item in list" @click="change(item)" :key="item.value" :class="{active:value===item.value,group:group}" class="item">
            <label>
                <input type="radio" :checked="value===item.value">
                <span class="title">
                    {{ item.title }}
                </span>
            </label>
            <span v-show="item.tip" class="tip">{{ item.tip }}</span>
        </div>
    </div>
</template>
<script>
export default {
    props: ['value', 'list','group'],
    data() {
        return {

        }
    },
    computed: {
        current: function () {
            return this.value;
        }
    },
    methods: {
        change(item){
            this.$emit('input',item.value);
        }
    }
}
</script>
<style scoped lang="less">
    .c-radio{
        display: flex;
        .item{
            padding: 8px 20px;
            margin-right: 80px;
            border: 1px solid transparent;
            display: flex;
            color: #333;
            align-items: center;
            cursor: pointer;
            label{
                display: flex;
                //font-weight: 500;
                align-items: center;
                input{
                    margin-right: 8px;
                }
            }
            &.active{
                color: #307DDB;
                &.group{
                    background: rgba(48,125,219,0.1);
                    border: 1px solid #307DDB;
                }
            }
            .tip{
                margin-left: 50px;
                font-size: 12px;
                color: #666666;

            }
        }

    }
</style>