<template>
    <div class="quan-item">
        <div class="top-title">
            <div class="tip">会员券</div>
            <div class="tip">活动可用</div>
        </div>
        <div class="body">
            <span class="pre">
                    ¥
            </span>
            <span class="title">
                {{ detail.price }}
            </span>
        </div>
        <div class="footer">
            无门槛优惠券
        </div>
    </div>
</template>
<script>
export default {
    props: ['detail'],
    data() {
        return {}
    }
}
</script>
<style scoped lang="less">
.quan-item {
    padding: 20px;
    width: 320px;
    //height: 120px;
    background: url("~@/assets/images/quan-bg.png") top center no-repeat;
    background-size: 100% 100%;

    .top-title {
        .tip {
            display: inline-block;
            border-radius: 2px;
            margin-right: 10px;
            color: #a74e05;
            padding: 2px 4px;
            background: linear-gradient(79deg, #ffe9c3 49.11%, #fff5e3 68.01%, #ffe9c3 90.37%);
        }
    }

    .body {
        padding-top: 15px;
        text-align: center;

        .pre {
            font-size: 20px;
            color: #ffd9c2;
        }

        .title {
            color: #ffd9c2;
            font-weight: bold;
            font-size: 40px;
            position: relative;
            display: inline-block;
            //&::before{
            //    display: block;
            //    position: absolute;
            //    bottom: 4px;
            //    left: -20px;
            //    content: '¥';
            //    font-size: 20px;
            //    color: #ffd9c2;
            //}
        }
    }

    .footer {
        padding-top: 5px;
        color: #ffd9c2;
        text-align: center;
    }
}
</style>