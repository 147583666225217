<template>
    <div class="profile">
        <div class="text-center">
            <img class="face" :src="userData.face||'https://api.bigemap.com/uploads/face/20210617/16238961269877.png'" alt="">
        </div>
        <div class="username">
            {{userData.nickname||userData.username}}
        </div>
    </div>
</template>
<script>
    export default {
        mounted() {

        },
        computed:{
            userData:function (){
                return this.$store.state.userData;
            }
        }
    }
</script>
<style scoped lang="less">
    .profile{
        background-color: #fff;
        margin-top: 10px;
        padding: 20px 0;
        .face{
            border-radius: 100%;
            width: 60px;
            padding: 2px;
            border: 1px solid #ddd;
            height: 60px;
        }
        .username{
            padding-top: 5px;
            text-align: center;
            font-weight: 500;
        }
    }
</style>