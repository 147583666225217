<template>
    <div class="profile-area">
        <el-dropdown @command="handleCommand">
            <el-avatar :src="$store.state.userData.face||'https://api.bigemap.com/uploads/face/20210617/16238961269877.png'"></el-avatar>
            <el-dropdown-menu slot="dropdown">
                <el-dropdown-item command="profile" icon="el-icon-info">个人中心</el-dropdown-item>
                <el-dropdown-item v-if="$store.state.userData.username" command="out" icon="el-icon-switch-button">
                    退出
                </el-dropdown-item>
            </el-dropdown-menu>
        </el-dropdown>
        <!--        <div>-->
        <!--            <el-button class="add-answer" @click="addQuestion" type="primary" round>提问</el-button>-->
        <!--        </div>-->
    </div>
</template>
<script>
import Cookie from "js-cookie";
import storage from "@/utils/storage";

export default {
    name: "userProfile",
    methods: {
        addQuestion() {
            //监听这个变量来展示添加问题的窗口
            if (!this.$tools.checkLogin()) {
                return;
            }
            this.$store.state.add_question = Math.random();
        },
        handleCommand: function (command) {
            switch (command) {
                case 'out':
                    Cookie.set(process.env.VUE_APP_COOKIE, null);
                    storage.clear();
                    this.$message.success('您已经成功退出登录');
                    this.$store.commit('clearUserData', {});
                    this.$router.push({
                        path:'/',
                    });
                    break;
                case 'profile':
                    if (!this.$tools.checkLogin()) {
                        return;
                    }
                    this.$route.path !== '/user' ? this.$router.push('/user') : true;
                    break;
            }
        }
    }
}
</script>

<style scoped lang="less">
.profile-area {
    width: 145px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .add-answer {
        padding: 8px 30px;
    }
}
</style>
