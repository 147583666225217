<template>
    <div class="left-part">
        <user-header></user-header>
        <menu-list></menu-list>
    </div>
</template>
<script>
import UserHeader from "@/components/user/left/user-header.vue";
import MenuList from "@/components/user/left/menu-list.vue";

export default {
    components: {
        UserHeader, MenuList
    },
    data() {
        return {}
    }
}
</script>
<style scoped lang="less">
    .left-part{
        flex-shrink: 0;
        width: 220px;
    }
</style>