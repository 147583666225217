<template>
    <div style="background-color: #f1f1f1;margin:-15px;padding: 15px">
        <div class="one step">
            <div class="back-to">
                <el-button @click="goBack" icon="el-icon-arrow-left" size="mini">
                    <span style="letter-spacing: 10px;text-align: center;">返回</span>
                </el-button>
            </div>
            <div class="title">
                1.选择开票类型
            </div>
            <div class="radio-item">
                <my-radio group="1" v-model="invoice.invoice_format" :list="list.invoice_format"></my-radio>
            </div>
            <div class="radio-item">
                <my-radio group="1" v-model="invoice.invoice_type" :list="list.invoice_type"></my-radio>
            </div>
        </div>
        <el-form :model="invoice" :rules="rules" ref="invoiceForm" label-width="120px" class="invoice-form"
                 :validate-on-rule-change="false">
            <el-input v-model="invoice.order_id" type="hidden"></el-input>
            <div class="step">
                <div class="title">
                    2.填写开票信息
                </div>
                <el-form-item label="发票抬头:" prop="header">
                    <el-autocomplete
                        class="inline-input"
                        v-model="invoice.header"
                        :fetch-suggestions="querySearch"
                        placeholder="请填写企业抬头"
                        @select="handleSelect"
                    ></el-autocomplete>
                </el-form-item>
                <el-form-item v-show="fields.more.show" class="input-item" v-for="item in fields.en" :key="item.name"
                              :label="item.title" :prop="item.name">
                    <el-input v-model="invoice[item.name]" :placeholder="item.placeholder"></el-input>
                </el-form-item>
                <div class="more">
                    <span @click="toggleMore">{{ fields.more.show ? '折叠信息' : '更多发票信息（选填）' }}</span>
                </div>
                <el-form-item class="input-item" v-for="item in fields.else" :key="item.name" :label="item.title"
                              :prop="item.name">
                    <el-input v-model="invoice[item.name]" :placeholder="item.placeholder"></el-input>
                </el-form-item>
            </div>
            <div class="step">
                <div class="title">
                    3.填写开票信息
                </div>
                <el-form-item class="input-item" v-for="item in post_fields" :key="item.name" :label="item.title"
                              :prop="item.name">
                    <el-input v-model="invoice[item.name]" :placeholder="item.placeholder"></el-input>
                </el-form-item>
            </div>
        </el-form>
        <div class="footer">
            <div class="price">
                <div class="title">
                    开票金额:
                </div>
                <div class="value">
                    ¥{{ invoice.price }}
                </div>
            </div>
            <div @click="submitData" class="submit-area">
                <span>提交</span>
            </div>
        </div>
    </div>
</template>
<script>
// import F from '@/fields/fields';
import MyRadio from "@/components/user/buy/invoice/my-radio.vue";
// import MyInput from "@/components/user/buy/invoice/my-input.vue";

export default {
    name: "appSms",
    components: {
        MyRadio
    },
    created() {

    },
    computed: {
        rules: function () {
            let all = {
                header: [
                    {required: true, message: '发票抬头不可以为空', trigger: 'input'},
                ],
                no: [
                    {required: true, message: '开户行账号不可以为空', trigger: 'input'},
                ],
                bank: [
                    {required: true, message: '开户行不可以为空', trigger: 'input'},
                ],
                tel: [
                    {required: true, message: '电话不可以为空', trigger: 'input'},
                ],
                address: [
                    {required: true, message: '注册地址不可以为空', trigger: 'input'},
                ],
                account: [
                    {required: true, message: '开户行账号不可以为空', trigger: 'input'},
                ],
                recipient_name: [
                    {required: true, message: '收件人姓名不可以为空', trigger: 'input'},
                ],
                recipient_tel: [
                    {required: true, message: '收件人电话不可以为空', trigger: 'input'},
                ],
                recipient_addr: [
                    {required: true, message: '邮寄地址不可以为空', trigger: 'input'},
                ],
            };
            let need = ['header'];
            if (this.invoice.invoice_type === 'zhuan') {
                need = ['header', 'tel', 'account', 'address', 'no', 'bank'];
            }
            if (this.invoice.invoice_format === 'paper') {
                need.push(...['recipient_name', 'recipient_tel', 'recipient_addr']);
            }
            let ok = {};
            need.map(v => {
                ok[v] = all[v];
            });
            return ok;
        },
        post_fields: function () {
            let f = [
                {title: '收件人姓名', placeholder: '请填写收件人姓名', name: 'recipient_name'},
                {title: '收件人电话', placeholder: '请填写收件人电话', name: 'recipient_tel'},
                {title: '电子邮箱', placeholder: '请填写电子邮箱，用于接收电子凭证', name: 'mailbox'},
                {title: '邮寄地址', placeholder: '请填写邮寄地址，用于纸质发票邮寄', name: 'recipient_addr'},
            ];
            if (this.invoice.invoice_format === 'ele') {
                let e_f = [...f];
                e_f.pop();
                return e_f;
            } else {
                return f;
            }
        }
    },
    mounted() {
        this.$bbs.get(this.addInvoiceUrl, {
            params: {
                order_id: this.$route.query.order_id,
            },
            need: {
                error: true
            }
        }).then(data => {
            this.invoice.price = data.data.order.price;
            this.list.header = data.data.invoice_header || [];
        })
    },
    watch: {
        'invoice.invoice_type': function (v) {
            if (v === 'zhuan') {
                // this.invoice.invoice_user = 'enterprise';
                this.fields.more.show = true;
            }
            this.$refs.invoiceForm.clearValidate();
        },
        // 'invoice.invoice_user': function (v) {
        //     if (v === 'enterprise') {
        //         this.fields.more.show = true;
        //     }
        //     if (v === 'person') {
        //         this.invoice.invoice_type = 'pu';
        //     }
        // },
    },
    data: function () {
        return {
            addInvoiceUrl: '/bbs/invoice/apiao',
            searchInvoiceHeader: '/bbs/invoice/pslist',
            loading: false,
            invoice: {
                order_id: this.$route.query.order_id,
                price: '',
                invoice_format: 'ele',
                invoice_type: 'pu',
                // invoice_user: 'person',
                header: '',
            },
            list: {
                header: [],
                invoice_format: [{title: '电子发票', tip: '最快五分钟开具', value: 'ele'}, {
                    title: '纸质发票',
                    tip: '预计五天送达',
                    value: 'paper'
                }],
                invoice_type: [{title: '普通发票', tip: '', value: 'pu'}, {title: '专用发票', tip: '', value: 'zhuan'}],
                // invoice_user: [{title: '个人', tip: '', value: 'person'}, {
                //     title: '企业',
                //     tip: '',
                //     value: 'enterprise'
                // }],
            },
            fields: {
                more: {
                    show: false,
                },
                base: [
                    {title: '开票抬头', placeholder: '请填写企业抬头', name: 'header'},
                ],
                en: [
                    {title: '纳税人识别号', placeholder: '请填写纳税人识别号', name: 'no'},
                    {title: '开户行', placeholder: '请填写开户行', name: 'bank'},
                    {title: '开户行账号', placeholder: '请填写开户行账号', name: 'account'},
                    {title: '注册地址', placeholder: '请填写注册地址', name: 'address'},
                    {title: '电话', placeholder: '请填写电话', name: 'tel'},
                    // {title: '发票内容', placeholder: '请填写发票内容', name: 'content'},
                ],
                else: [
                    {title: '开票备注', placeholder: '请填写备注', name: 'tip'},
                ],
            },
        }
    },

    methods: {
        toggleMore() {
            this.fields.more.show = !this.fields.more.show;
        },
        querySearch(queryString, cb) {
            let results = [];
            if (!queryString) {
                results = this.list.header;
            } else {
                results = this.list.header.filter(v => v.label.indexOf(queryString) > -1);
            }
            results = results.map(v => {
                v = JSON.parse(JSON.stringify(v));
                v.value = v.label;
                return v;
            });
            if (!results.length) {
                this.$bbs.get(this.searchInvoiceHeader, {
                    timeout: 2000,
                    params: {
                        title: queryString,
                    }
                }).then(data => {
                    let result=data.data.map(v=>{
                        v.value=v.header.trim();
                        return v;
                    });
                    cb(result);
                }).catch(()=>{
                    cb([]);
                });
            } else {
                cb(results);
            }
        },
        handleSelect(item) {
            // if (item.type === 'zhuan') {
            //     this.invoice.invoice_type = 'zhuan';
            // } else {
            //     this.invoice.invoice_type = 'pu';
            // }
            ['no', 'content', 'bank', 'address', 'tel', 'account'].map(v => {
                if (item[v]) {
                    this.$set(this.invoice, v, item[v].trim());
                }
            });
        },
        goBack() {
            this.$router.push({
                path: '/user/buy/list',
            });
        },
        submitData: function () {
            if (this.loading) return;
            this.loading = true;
            this.$refs.invoiceForm.validate((valid) => {
                if (valid) {
                    this.$bbs.post(this.addInvoiceUrl, this.invoice, {
                        need: {
                            error: true,
                        }
                    }).then(() => {
                        this.loading = false;
                        this.$message.success('提交成功!');
                        this.$router.push({
                            path: '/user/buy/list',
                        });
                    }).catch(() => {
                        this.loading = false;
                    });
                } else {
                    this.loading = false;
                    return false;
                }
            });
        }
    }
}
</script>

<style lang="less" scoped>
.back-to {
    padding-top: 5px;
    padding-bottom: 15px;
}

.invoice-form {
    ::v-deep {
        .el-input {
            width: 400px;

            input {
                padding: 5px 10px;
                border: none;
                height: 28px;
                outline: none;
                border-radius: 0;
                border-bottom: 1px solid rgba(151, 151, 151, 0.7);
            }
        }
    }
}

.step {
    background-color: #fff;
    padding: 15px;
    margin-bottom: 10px;

    .title {
        font-size: 14px;
        color: #666666;
        font-weight: 500;
    }

    .radio-item {
        padding: 10px 0;
    }

    .input-item {
        //padding: 15px 0;

        &.header {
            display: flex;
            align-items: center;

            .title {
                font-weight: bold;
                width: 120px;
                text-align: right;
                font-size: 14px;
            }

            .v {
                flex: 1;
            }
        }
    }

    .more {
        padding: 0 0 10px 50px;

        span {
            color: #307DDB;
            cursor: pointer;
            font-size: 12px;
        }
    }
}

.footer {
    background-color: #fff;
    padding: 10px;

    .price {
        padding: 10px 0;
        align-items: baseline;
        display: flex;

        .title {
            text-align: right;
            width: 110px;
            font-weight: 500;
            color: #333;
        }

        .value {
            flex: 1;
            color: #007FEA;
            font-size: 18px;
            font-weight: bold;
            padding-left: 20px;
        }
    }

    .submit-area {
        text-align: center;
        padding: 10px;
        letter-spacing: 50px;
        font-weight: 500;
        cursor: pointer;
        color: #fff;

        background-color: #307DDB;
    }
}

</style>
